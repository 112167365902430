import React from "react"

import { navigate } from "gatsby"

import { CONTENT_TYPES, EVENT_TYPES } from "@constants/recommender-system"
import SearchWrapper from "@layout/header/search-bar/search-wrapper"
import { sendLogToRecommender } from "@utils/recommender"
import { isValidArray } from "@utils/variables.helpers"

import Submenu from "../submenu/submenu"

import * as styles from "./navbar-desktop.module.css"

const NavbarDesktop = ({ mainLinks, menuLinks, location }) => {
  const onCategoryHover = () => {
    try {
      const submenuClass = document.getElementsByClassName(styles.submenu)
      Array.from(submenuClass).forEach((element) => {
        element.classList.remove(styles.hideNav)
      })
    } catch (e) {
      console.error(e)
    }
  }

  const onCategoryClick = (category, isTopMenu = false) => {
    if (category && category.navigationId) {
      sendLogToRecommender({
        event: EVENT_TYPES.NAVBAR_CATEGORY,
        content_id: category.navigationId,
        content_type: CONTENT_TYPES.CATEGORY,
      })
    }
    if (category.link) {
      navigate(category.link)
    }

    try {
      const submenuClass = document.getElementsByClassName(styles.submenu)
      if (submenuClass && isTopMenu === true) {
        Array.from(submenuClass).forEach((element) => {
          element.classList.remove(styles.hideNav)
        })
      }
      if (submenuClass && isTopMenu === false) {
        Array.from(submenuClass).forEach((element) => {
          element.classList.add(styles.hideNav)
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <SearchWrapper menuLinks={menuLinks} location={location} />
      <nav className={styles.navbar}>
        <ul className={styles.menu}>
          {mainLinks.map((navigationItem, index) => {
            const customStyle = {}
            let isButton = false
            if (navigationItem.backgroundColor) {
              customStyle.backgroundColor = navigationItem.backgroundColor
              isButton = true
            }
            if (navigationItem.color) {
              customStyle.color = navigationItem.color
            }
            return (
              <li key={index} className={styles.item}>
                <div
                  className={`${styles.link} ${
                    isValidArray(navigationItem.children)
                      ? styles.navBtnExpand
                      : ""
                  }`}
                  onMouseEnter={onCategoryHover}
                  onClick={() => onCategoryClick(navigationItem, true)}
                >
                  <div
                    style={customStyle}
                    className={isButton ? "btn " + styles.navBtn : ""}
                  >
                    {navigationItem.name}
                  </div>
                </div>
                {navigationItem.children &&
                  navigationItem.children.length > 0 && (
                    <Submenu
                      navItems={navigationItem.children}
                      containerClass={styles.submenu}
                      alignment={navigationItem.alignment}
                      onCategoryClick={onCategoryClick}
                    />
                  )}
              </li>
            )
          })}
        </ul>
      </nav>
    </>
  )
}

export default NavbarDesktop
