import React from "react"

import * as styles from "./feefo-header.module.css"

const FeefoHeader = () => {
  return (
    <a
      href="https://www.feefo.com/reviews/first-fence"
      target="_blank"
      rel="noreferrer"
      className={styles.container}
    >
      <img
        alt="Feefo logo"
        border="0"
        src="https://api.feefo.com/api/logo?merchantidentifier=first-fence"
        title="See what our customers say about us"
      />
    </a>
  )
}

export default FeefoHeader
