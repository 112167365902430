import React from "react"

import * as styles from "./feefo-widget.module.css"

const FeefoWidget = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div
          id="feefo-service-review-carousel-widgetId"
          className="feefo-review-carousel-widget-service"
        ></div>
      </div>
    </div>
  )
}

export default FeefoWidget
