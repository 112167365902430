import React from "react"

import { Icon } from "@components/icon"

import SocialLinks from "../social-links/social-links"

import * as styles from "./mailing-list-banner.module.css"

const MailingListBanner = () => {
  return (
    <div className={styles.container}>
      <a
        href="https://firstfence.us4.list-manage.com/subscribe?u=dcfdcb60cce60745f56cf7a68&id=cabd2e4958"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.joinButton}
      >
        <span>Join Our Mailing List</span>
        <Icon id="arrow-right" width="18" height="18" />
      </a>
      <div className={styles.connectText}>CONNECT WITH US</div>
      <SocialLinks />
    </div>
  )
}

export default MailingListBanner
