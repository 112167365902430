import React from "react"

import { Icon } from "@components/icon"

import * as styles from "./social-links.module.css"

const SocialLinks = () => {
  return (
    <div className={styles.container}>
      <a
        href={`https://www.facebook.com/firstfenceltd`}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="First Fence Facebook"
      >
        <Icon
          id="square-facebook"
          width="30"
          height="30"
          className={styles.socialIcon}
          aria-hidden="true"
        />
      </a>
      <a
        href={`https://www.linkedin.com/company/firstfenceltd`}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="First Fence LinkedIn"
      >
        <Icon
          id="linkedin"
          width="30"
          height="30"
          className={styles.socialIcon}
          aria-hidden="true"
        />
      </a>
      <a
        href={`https://www.instagram.com/firstfenceltd`}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="First Fence Instagram"
      >
        <Icon
          id="instagram"
          width="30"
          height="30"
          className={styles.socialIcon}
          aria-hidden="true"
        />
      </a>
      <a
        href={`https://twitter.com/firstfenceltd`}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="First Fence Twitter"
      >
        <Icon
          id="x-twitter"
          width="30"
          height="30"
          className={styles.socialIcon}
          aria-hidden="true"
        />
      </a>
      <a
        href={`https://www.youtube.com/c/FirstfenceCoUk`}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="First Fence YouTube"
      >
        <Icon
          id="youtube"
          width="30"
          height="30"
          className={styles.socialIcon}
          aria-hidden="true"
        />
      </a>
    </div>
  )
}

export default SocialLinks
